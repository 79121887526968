<template>
  <div>
    <div class="flex mt-2">
      <b-form-group label-for="name" label="Name">
        <validation-provider #default="{ errors }" name="Name">
          <b-form-input
            id="name"
            v-model="policy.name"
            placeholder="Enter name of the policy"
            :state="errors.length > 0 ? false : null"
            style="max-width: 40%"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </div>

    <p class="statements-label mt-3">
        Statements:
    </p>

    <statement-editor 
      v-for="(statement, index) of policy.statements"
      :key="statement._id"
      :resources="resources" 
      :editing-statement="statement"
      :expand="expandStatement"
      @change="onStatementsChanged(index, $event)" 
      @delete-statement="onRemoveStatement(index)"
      @clone-statement="cloneStatementHandler(index)"
    />

    <div class="d-flex justify-content-end align-items-center" style="margin-top: -10px;">
      <div class="d-flex align-items-center cursor-pointer add-statement-container" @click="addStatement">
        <feather-icon icon="PlusCircleIcon" size="20" />
        <span style="font-weight: 400; font-size: 14px;line-height: 160%; margin-left: 7px;">Add new statement</span> 
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <b-button
        variant="primary"
        size="lg"
        @click="$emit('save', policy)"
      >
        {{ actionTitle }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BTabs,
  BTab,
  BCardText,
  BFormInput,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import { pick } from "lodash";

import VueJSONEditor from "./JSONEditor.vue";
import StatementEditor from "./StatementEditor.vue";

const defualtStatement = () => ({
  actions: [],
  effect: "Allow",
  resource: "*"
})

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    VueJSONEditor,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BButton,
    StatementEditor,
  },
  props: {
    resources: {
      type: Array,
      required: true,
      default: () => [],
    },
    editingPolicy: {
      type: Object,
      required: false,
      default: () => ({})
    },
    expandStatement: {
      type: Boolean,
      required: false,
      default: false
    },
    actionTitle: {
      type: String,
      required: false,
      default: "Create Policy"
    }
  },
  data() {
    return {
      policy: {
        name: "",
        statements: [defualtStatement()],
      },
    };
  },
  created() {
    if (this.editingPolicy._id) {
      const { name, statements = [], version } = this.editingPolicy;
      this.policy = {
        name, 
        version,
        statements: statements.map(statement => pick(statement, ["actions", "resource", "effect"]))
      }
    }
  },
  methods: {
    onStatementsChanged(index, statement) {
      this.policy.statements[index] = statement;
    },
    addStatement() {
      this.policy.statements.push(defualtStatement())
    },
    onRemoveStatement(index) {
      this.policy.statements.splice(index, 1);
    },
    cloneStatementHandler(index) {
      const clonedStatement = { ...this.policy.statements[index] }
      this.policy.statements.push(clonedStatement)
    }
  },
};
</script>
<style scoped>
.statements-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;

  color: #000000;
}

.add-statement-container {
  color: #459042;
}

.add-statement-container:hover {
  opacity: 0.8;
}
</style>
