<template>
  <b-overlay :show="loading">
    <div ref="editor"></div>
  </b-overlay>
</template>

<script>


import { BOverlay } from 'bootstrap-vue';
import { JSONEditor } from "@json-editor/json-editor";

const propNames = [
'content',
'mode',
'mainMenuBar',
'navigationBar',
'statusBar',
'readOnly',
'indentation',
'tabSize',
'escapeControlCharacters',
'escapeUnicodeCharacters',
'validator',
'onError',
'onChange',
'onChangeMode',
'onClassName',
'onRenderValue',
'onRenderMenu',
'queryLanguages',
'queryLanguageId',
'onChangeQueryLanguage',
'onFocus',
'onBlur',
];


export default {
  name: 'JsonEditor',
  components: {
    BOverlay
  },
  props: propNames,
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.fetchSchema();
  },
  methods: {
    async fetchSchema() {
      try {
        this.loading = true;
        const request = await this.useJwt().adminService.fetchPermissionsSchema();
        const response = this.getValueFromSource(request, 'data.data');

        const schema = this.getValueFromSource(response, 'schema', {});
        const actions = this.getValueFromSource(response, 'actions', []);

        const callbacks = {
          autocomplete: {
            search_actions: function search(jseditor_editor, input) {
              if (input.length < 3) {
                return [];
              }

              return actions.filter(action => action.permission_action.toLowerCase().startsWith(input.toLowerCase()))
            },
            getResultValue_actions: function getResultValue(jseditor_editor, result) {
              return result.permission_action;
            },
            renderResult_actions: (jseditor_editor, result, props) => {
              return [
                '<li ' + props + '>',
                  '<div class="permission-action"><small>' + result.title + '</small></div>',
                  '<div class="permission-action" style="color: green">' + result.permission_action + '</div>',
                  '<div class="wiki-snippet"><small>' + result.description + ' | ' + result.access_level + '<small></div>',
                '</li>'
              ].join('');
            }
          }
        }

        JSONEditor.defaults.callbacks = callbacks;
        const editor = new JSONEditor(this.$refs.editor, {
          schema,
          compact: true,
          theme: 'bootstrap4',
          form_name_root: 'Policy',
          iconlib: 'fontawesome4',
          disable_edit_json: false,
          disable_properties: true,
          show_errors: 'interaction',
          remove_button_labels: true,
          no_additional_properties: true,
        })
        console.log("editor", JSONEditor)

        editor.on('ready', () => {
          editor.setValue(this.content)
        });

        editor.on('change', () => {
          const errors = editor.validate();
          if (errors.length) {
            console.log("has error")
            this.$emit("disableUpdateButton");
          } else {
            this.$emit("enableUpdateButton");
            this.$emit("onChange", editor.getValue())
          }
        })
      } catch (e) {
        console.log('Error fetching schema', e)
      } finally {
        this.loading = false;
      }
    },
  },
  beforeUnmount() {
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style>
[data-schemapath='Policy'] label {
  margin-left: 7px !important;
}

[data-schemapath='Policy.statements'] {
  margin-top: 10px !important;
}

[data-schemapath='Policy.statements'] .card {
  border: 1px solid #c7c5c0;
  border-radius: 5px;
  padding: 10px 8px;
}

[data-schemapath='Policy.statements'] > .card.card-body > span.btn-group {
  margin-top: 10px;
}

[data-schemapath='Policy.statements'] > .card.card-body > div > [data-schematype='object']:not(:first-child) {
  margin-top: 10px;
}

.form-control.je-switcher {
  display: none;
}
</style>
