<template>
  <div class="my-1">
    <div class="d-flex align-items-center">
      <feather-icon
        size="20"
        :icon="expand ? 'ChevronUpIcon' : 'ChevronRightIcon'"
        @click="expand = !expand"
      />

      <b-form-checkbox
        v-model="select_all"
        name="select_all"
      >
        <span class="block m-auto py-1">{{ title }}</span>
      </b-form-checkbox>
    </div>

    <div v-if="expand && options.length > 0" class="access-level-actions">
      <b-form-group class="d-flex mt-2">
          <b-form-checkbox-group
            :key="renderKey"
            v-model="selected"
            :options="options"
            name="flavour-1"
            class="d-flex flex-wrap ml-2"
            style="gap: 10px;"
          ></b-form-checkbox-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BCardText,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { difference } from "lodash";

export default {
  name: "AccessLevelActionsSelector",
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    editingActions: {
      type: Array,
      required: false,
      default: () => [] 
    }
  },
  data() {
    return {
      expand: false,
      select_all: false,
      selected: [],
      renderKey: 1
    };
  },
  watch: {
    select_all: {
      handler(select_all) {
        if (!select_all) {
          this.selected = [];
        } else {
          this.selected = this.options.map(option => option.value)
        }
      }
    },
    selected: {
      handler() {
        this.$emit("change", this.selected)
      }
    },
    editingActions: {
      immediate: true, 
      handler(val) {
        const diff = difference(val, this.selected);
        if (diff.length === 0) return;
        this.selected = val;
        this.renderKey += 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.access-level-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  background: #EEF7EE;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
