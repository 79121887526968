<template>
<transition name="slide-fade">
  <b-card no-body class="p-1">
    <b-row align-v="start">
      <b-col cols="12" lg="6" md="6">
        <span style="font-weight: 500; font-size: 16px; line-height: 160%;">
          <feather-icon
            :icon="expanded ? 'ChevronUpIcon' : 'ChevronRightIcon'"
            size="20"
            class="cursor-pointer"
            @click="expanded = !expanded"
          />
          actions <span style="color: #459042">({{ actionsCount }})</span>
        </span>
      </b-col>
      <b-col cols="12" lg="6" md="6" class="d-flex justify-content-end">
        <span class="statement-actions" @click="$emit('clone-statement')">Clone</span>
        <span class="mx-1 statement-actions-divider">|</span>
        <span class="statement-actions" @click="removeStatement">Remove</span>
      </b-col>
    </b-row>
    <div v-if="expanded" class="flex mt-2">

      <b-form-group label-for="Resource" label="Resource">
        <validation-provider
          #default="{ errors }"
          name="Resource"
        >
          <v-select
            placeholder="Select a resource"
            style="max-width: 35%"
            :value="resource"
            :disabled="loading"
            :options="resourceOptions"
            :reduce="(val) => val.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            @input="(val) => (resource = val)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <div class="my-2"></div>

      <p class="title">
        Actions
      </p>
      <span style="font-size: 0.9rem">
        Specify the actions allowed for the selected resource
      </span>

      <p class="title mt-2">
        Manual Actions
      </p>

      <b-form-checkbox v-model="check_all" name="check_all" :disabled="!resource">
        Select All
      </b-form-checkbox>

      <p class="title mt-2">
        Access Levels
      </p>

      <AccessLevelActionsSelector
        v-for="category of Object.keys(categorizedResourceActions)"
        :key="category"
        ref="access_level_action_selectors"
        :title="category"
        :options="categorizedResourceActions[category]"
        :editing-actions="getEditingActionsInOptions(categorizedResourceActions[category], editingStatement.actions)"
        @change="onStatementsChanged($event, category)"
      />
    </div>
  </b-card>
</transition>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BCardText,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
  BCol,
  BRow
} from "bootstrap-vue";
import { get, pick, flatten } from "lodash";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccessLevelActionsSelector from "./AccessLevelActionsSelector.vue";

export default {
  name: "StatementEditor",
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    AccessLevelActionsSelector,
    BCol,
    BRow
  },
  props: {
    resources: {
      type: Array,
      required: true,
      default: () => [],
    },
    editingStatement: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    expand: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      resource: "",
      loading: false,
      selected_actions: [],
      resource_actions: [],
      check_all: false,
      statement: {
        actions: [],
        effect: "Allow",
        resource: "*"
      },
      actions: {
        View: [],
        Write: [],
        Delete: [],
        List: [],
      },
      expanded: false,
      actionsCount: 0
    };
  },
  computed: {
    resourceOptions() {
      return this.resources.map((resource) => ({
        label: resource.title,
        value: resource.resource_name,
      }));
    },
    categorizedResourceActions() {
      const unique_actions = new Set();
      const resource_actions = {
        View: [],
        Write: [],
        Delete: [],
        List: [],
      };
      this.resource_actions.forEach((action) => {
        const value = `${this.resource}:${action.name}`
        if (!unique_actions.has(value)) {
          resource_actions[action.access_level].push({
            text: action.name,
            value,
          });
          unique_actions.add(value)
        }
      });
      return resource_actions;
    },
  },
  watch: {
    resource: {
      handler() {
        this.fetchResourceActions();
      },
    },
    check_all: {
      handler(val) {
        this.$refs.access_level_action_selectors.forEach(ref => {
          ref.select_all = val
        })
      }
    }
  },
  created() {
    if (this.editingStatement && this.editingStatement.actions && this.editingStatement.actions.length > 0) {
      this.statement = this.editingStatement;
      this.actionsCount = this.editingStatement.actions.length;
    }
    this.expanded = this.expand
  },
  methods: {
    getEditingActionsInOptions(options, editingActions) {
      if (editingActions) {
        const setOfOptions = new Set(options.map(option => option.value))
        return editingActions.filter(action => setOfOptions.has(action))
      }
    },
    onStatementsChanged(actions, category) {
      this.actions[category] = actions;

      const statement = pick(this.statement, ["actions", "effect", "resource"])

      const newActions = new Set(flatten(Object.values(this.actions)))

      statement.actions = Array.from(newActions)

      this.$emit("change", statement);

      this.actionsCount = statement.actions.length;
    },
    async removeStatement() {
      const deleteConfirmation = await this.$swal({
        title: 'Delete this statement?',
        text: "This statement will be removed from this list. Continue?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (!deleteConfirmation.value){
        return;
      }

      this.$emit("delete-statement")
    },
    async fetchResourceActions() {
      try {
        const resource_id = this.resource;
        const response = await this.useJwt().adminService.getResourceActions({
          resource_id,
        });
        this.resource_actions = response.data.data;
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-label {
  font-size: 16px;
  line-height: 160%;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #727272;
}

.statement-actions-divider {
  color: #459042; 
  font-weight: 600; 
  font-size: 14px; 
  line-height: 160%;
}

.statement-actions {
  color: #459042; 
  font-weight: 600; 
  font-size: 14px; 
  line-height: 160%;
  cursor: pointer;
}

.statement-actions:hover {
  opacity: 0.8;
}
</style>

